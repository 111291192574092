<template>
  <vz-dialog
    public
    ref="dialog"
    name="vz-backend-stub-modal"
    class="vz-backend-stub-modal"
    hide-header
    hide-footer
  >
    <div class="vz-backend-stub-modal-content">
      <img src="/images/stub.png" alt="">
      <div class="vz-backend-stub-modal-text mt-20">
        <div class="vz-backend-stub-modal-caption mb-5">
          На странице сайта ведутся
        </div>
        технические работы
        <div class="vz-backend-stub-modal-caption mt-5">
          Попробуйте зайти на нее позже
        </div>
      </div>
      <div class="vz-backend-stub-modal-list mt-20 flex flex-wrap">
        <nuxt-link
          v-for="item, index in items"
          :key="index"
          :to="item.to"
        >
          {{ item.title }}
        </nuxt-link>
      </div>
    </div>
  </vz-dialog>
</template>

<script setup lang="ts">
import useMenuItems from "~/composables/useMenuItems";

const {isHideInsurance} = useMenuItems()
const publicItems = computed(() => {
  const result = [
    {
      title: 'Новости',
      to: '/news/'
    },
    {
      title: 'Упаковка',
      to: '/wrapping/'
    },
    {
      title: 'Информация',
      to: '/information/'
    },
    {
      title: 'Страхование',
      to: '/insurance/'
    },
    {
      title: 'Услуги',
      to: '/services/'
    },
    {
      title: 'Акции',
      to: '/actions/'
    },
  ]

  if (isHideInsurance) {
    const findInsurance = result.findIndex((val) => val.to === '/insurance/')
    result.splice(findInsurance, 1)
  }

  return result
})

const personalItems = [
  {
    title: 'Контрагенты',
    to: '/personal/contractors/'
  },
  {
    title: 'Бонусы',
    to: '/personal/bonus/task/'
  },
  {
    title: 'События',
    to: '/personal/'
  },
  {
    title: 'Скидки',
    to: '/personal/discount/'
  }
]

const isPersonal = computed(() => {
  return useRoute().path.startsWith('/personal/')
})

const items = computed(() => {
  return isPersonal.value
    ? personalItems
    : publicItems.value
})
</script>
